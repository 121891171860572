
  import Teaser from "../components/Teaser.vue";
  import Info from "../components/Info.vue";
  import { requestAuth } from "@pons/auth";
  import Modal from "../components/Modal.vue";
  import { defineComponent } from "vue";
  import { api, codeSyntaxChecker, recaptchaUtils } from "@/lib/di";
  import { clearState, getState, loadVar, saveVar, setState } from "@/lib/CodeActivationStateUtils";


  export default defineComponent({
    name: "ActivateCode",
    components: { Teaser, Modal, Info },

    data() {
      return {
        code: "",
        errorMessage: "",
        showSubscriptionCodeValidModal: false,
        showAlmostCompleteDialog: false,
        subscriptionAppTitle: "",
        almostCompleteModal: {
          title: "Fast geschafft",
          description: "Lade dir nun die Langenscheidt Schule-App herunter und melde dich an, um dein Wörterbuch zu benutzen.",
          textLinkAction: "Weitere Codes einlösen",
          infoText: "Du hast die App bereits heruntergeladen? Dann logge dich bitte noch einmal neu in der App ein, um dein freigeschaltetes Wörterbuch herunterladen zu können."
        },
        subscriptionCodeValidModal: {
          title: "Dein Code ist gültig!",
          description: "Bitte melde dich nun mit deinem Langenscheidt-Konto an oder registriere dich, um die Schule-App kostenlos nutzen zu können.",
          secondaryAction: "Registrieren",
          primaryAction: "Anmelden"
        },
      }
    },

    async mounted() {
      const state = getState();

      if (state === "Login-Or-Register") {
        try {
          await this.supplySubscriptionCode();
        } catch (e) {
          this.setErrorMessage(e.message);
        }
      } else {
        clearState();
      }
    },

    methods: {
      setErrorMessage(text: string) {
        this.errorMessage = `Huch! Da ist etwas schiefgelaufen: ${text}`;
      },

      handleCloseClick(): void {
        if (this.showAlmostCompleteDialog) {
          this.showAlmostCompleteDialog = false;
        }
        else if (this.showSubscriptionCodeValidModal) {
          this.showSubscriptionCodeValidModal = false;
        }
      },

      handleMoreClick(): void {
        if (this.showAlmostCompleteDialog) {
          this.showAlmostCompleteDialog = false;
        }
      },

      handleSecondaryClick(): void {
        if (this.showAlmostCompleteDialog) {
          this.showAlmostCompleteDialog = false;
          this.openAppStore();
        }
        else if (this.showSubscriptionCodeValidModal) {
          this.showSubscriptionCodeValidModal = false;
          this.registerForSubscriptionCode();
        }
      },

      handlePrimaryClick(): void {
        if (this.showAlmostCompleteDialog) {
          this.showAlmostCompleteDialog = false;
          this.openPlayStore();
        }
        else if (this.showSubscriptionCodeValidModal) {
          this.showSubscriptionCodeValidModal = false;
          this.loginForSubscriptionCode();
        }
      },

      async openPlayStore(): Promise<void> {
        await this.openInNewTab(process.env.PLAY_STORE_URI);
      },

      async openAppStore(): Promise<void> {
        await this.openInNewTab(process.env.APP_STORE_URI);
      },

      async openInNewTab(uri: string): Promise<void> {
        window.open(uri, "_blank");
      },

      async registerForSubscriptionCode(): Promise<void> {
        setState("Login-Or-Register");
        await requestAuth({
          performAuthOnEmpty: true,
          registerExpected: true
        });
      },

      async loginForSubscriptionCode(): Promise<void> { // 3BB
        setState("Login-Or-Register");
        await requestAuth({
          performAuthOnEmpty: true,
        })
      },

      async supplySubscriptionCode(): Promise<void> { // 5BB
        const code = loadVar("Code");

        const user = await requestAuth({
          performAuthOnEmpty: false
        });

        if (user === null)
          throw Error("Not logged in");

        const accessToken = user.access_token;
        const supplyResponse = await api.supplyCode(code, accessToken);

        clearState();

        this.subscriptionAppTitle = supplyResponse.productTitle;
        this.showAlmostCompleteDialog = true;
      },

      async activateCodeButtonClicked(): Promise<void> {
        try {
          const code = await codeSyntaxChecker.format(this.code);
          const recaptchaCode = await recaptchaUtils.verify((this.$refs as any)["code-form"]);

          const response = await api.validateCode({
            code,
            recaptchaCode
          });

          saveVar("Code", code);

          if (response.type == "REDIRECT") {
            await this.openInNewTab(response.uri);
          } else if (response.type == "SUBSCRIPTION") {
            const user = await requestAuth({
              performAuthOnEmpty: false
            });

            if (user === null) {
              this.showSubscriptionCodeValidModal = true;
            } else {
              await this.supplySubscriptionCode();
            }
          } else {
            throw new Error("Invalid Code type");
          }
        } catch (e) {
          this.setErrorMessage(e.message);
        }
      }
    }

  });
