<template>
  <div class="teaser">
    <div class="hero">
      <!-- <img alt="Papierticket" src="../assets/title-image.jpg"> -->
      <picture>
        <source srcset="../assets/code-input-large.jpg" type="image/jpeg">
        <source srcset="../assets/code-input-medium.jpg" media="(max-width: 599px)"
                type="image/jpeg">
        <source srcset="../assets/code-input-large.jpg" media="(min-width: 1024px)"
                type="image/jpeg">
        <img src="../assets/code-input-small.jpg" itemprop="image">
      </picture>
    </div>
    <div class="yellow"/>
    <div class="container">
      <div class="teaser-text">
        <h1>
          Yes! Oui! Sí! Ja! Genau hier gibt’s deine kostenlose App
        </h1>
        <p>
          Ob in der Schule, zu Hause oder unterwegs: Mit der <b>Langenscheidt Schule-App</b> kannst du jederzeit und von überall auf deine Langenscheidt Wörterbücher zugreifen - ganz ohne Internetverbindung. Gib einfach den Code aus deinem Langenscheidt Wörterbuch ein. Viel Vergnügen mit deiner App!
        </p>
      </div>
    </div>
  </div>

</template>

<script>
  import { defineComponent } from "vue";

  export default defineComponent({
    name: "Teaser",
  });
</script>

<style lang="scss" scoped>
  @import '../assets/variables';

  .container {
    padding: 0 1.25rem;
    display: flex;
    justify-content: center;

    @media (min-width: $break-xl) {
      width: 75rem;
      margin: 0 auto;
    }
  }

  .teaser {
    .hero {
      width: 100%;
      max-height: 50vh;
      overflow: hidden;

      img {
        width: 140%;
        margin-left: -20%;
        height: auto;
        object-fit: cover;
        overflow: hidden;
        vertical-align: bottom;


        @media (min-width: $break-2xs) {
          width: 130%;
          margin-left: -15%;
        }

        @media (min-width: $break-xs) {
          width: 100%;
          margin: 0;
        }
      }
    }

    .yellow {
      position: absolute;
      background: $color-primary;
      height: 2rem;
      width: 50%;
      margin-top: -2rem;

      @media (min-width: $break-sm) {
        height: 5.5rem;
        margin-top: -5.5rem;
      }
      @media (min-width: $break-xl) {
        max-width: 37.5rem;
        right: 50%;
      }
    }

    .teaser-text {
      padding: 2.25rem 1.875rem 0;
      position: relative;
      margin-top: -3.75rem;
      background: white;
      color: $color-accent-darkest;

      @media (min-width: $break-2xs) {
        margin-top: -8.125rem;
      }
      @media (min-width: $break-sm) {
        width: 65%;
        padding: 3.75rem 3.75rem 0;
      }
      @media (min-width: $break-md) {
        width: 70%;
      }

      h1 {
        font-size: 1.5rem;
        line-height: 1.5625rem;
        font-weight: 400;
        margin-bottom: 2.3125rem;

        @media (min-width: $break-xs) {
          font-size: 2.25rem;
          line-height: 2.25rem;
        }
        @media (min-width: $break-sm) {
          font-size: 3rem;
          line-height: 3.375rem;
        }
      }

      p {
        line-height: 1.9em;
        margin-bottom: 3.813rem;

        @media (min-width: $break-2xs) {
          margin-bottom: 6.5rem;
        }
      }
    }

  }
</style>
