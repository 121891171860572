<template>
  <div class="activate-code">
    <teaser/>
    <div class="code">

      <div class="container">
        <div class="code-message">
          <h2>Hier den Code aus deinem Buch eingeben</h2>
        </div>
      </div>

      <div class="code-form">
        <div class="container">
          <form ref="code-form">
            <input @input="formatCode" v-model="code" type="text" maxlength="17" placeholder="Code eingeben">
            <button @click.prevent="activateCodeButtonClicked">aktivieren</button>
          </form>
          <div class="error-message" :class="errorMessage !== '' ? 'active' : ''">
            <img src="../assets/attention.svg" alt="Attention symbol"/>
            <span v-html="errorMessage"></span>
          </div>
        </div>
      </div>

      <info/>
    </div>

    <modal
      v-if="showSubscriptionCodeValidModal || showAlmostCompleteDialog"
      :content="showSubscriptionCodeValidModal ? subscriptionCodeValidModal : almostCompleteModal"
      @handle-secondary-click="handleSecondaryClick"
      @handle-primary-click="handlePrimaryClick"
      @handle-close-click="handleCloseClick"
      @handle-more-click="handleMoreClick" />
  </div>
</template>

<script lang="ts">
  import Teaser from "../components/Teaser.vue";
  import Info from "../components/Info.vue";
  import { requestAuth } from "@pons/auth";
  import Modal from "../components/Modal.vue";
  import { defineComponent } from "vue";
  import { api, codeSyntaxChecker, recaptchaUtils } from "@/lib/di";
  import { clearState, getState, loadVar, saveVar, setState } from "@/lib/CodeActivationStateUtils";


  export default defineComponent({
    name: "ActivateCode",
    components: { Teaser, Modal, Info },

    data() {
      return {
        code: "",
        errorMessage: "",
        showSubscriptionCodeValidModal: false,
        showAlmostCompleteDialog: false,
        subscriptionAppTitle: "",
        almostCompleteModal: {
          title: "Fast geschafft",
          description: "Lade dir nun die Langenscheidt Schule-App herunter und melde dich an, um dein Wörterbuch zu benutzen.",
          textLinkAction: "Weitere Codes einlösen",
          infoText: "Du hast die App bereits heruntergeladen? Dann logge dich bitte noch einmal neu in der App ein, um dein freigeschaltetes Wörterbuch herunterladen zu können."
        },
        subscriptionCodeValidModal: {
          title: "Dein Code ist gültig!",
          description: "Bitte melde dich nun mit deinem Langenscheidt-Konto an oder registriere dich, um die Schule-App kostenlos nutzen zu können.",
          secondaryAction: "Registrieren",
          primaryAction: "Anmelden"
        },
      }
    },

    async mounted() {
      const state = getState();

      if (state === "Login-Or-Register") {
        try {
          await this.supplySubscriptionCode();
        } catch (e) {
          this.setErrorMessage(e.message);
        }
      } else {
        clearState();
      }
    },

    methods: {
      setErrorMessage(text: string) {
        this.errorMessage = `Huch! Da ist etwas schiefgelaufen: ${text}`;
      },

      handleCloseClick(): void {
        if (this.showAlmostCompleteDialog) {
          this.showAlmostCompleteDialog = false;
        }
        else if (this.showSubscriptionCodeValidModal) {
          this.showSubscriptionCodeValidModal = false;
        }
      },

      handleMoreClick(): void {
        if (this.showAlmostCompleteDialog) {
          this.showAlmostCompleteDialog = false;
        }
      },

      handleSecondaryClick(): void {
        if (this.showAlmostCompleteDialog) {
          this.showAlmostCompleteDialog = false;
          this.openAppStore();
        }
        else if (this.showSubscriptionCodeValidModal) {
          this.showSubscriptionCodeValidModal = false;
          this.registerForSubscriptionCode();
        }
      },

      handlePrimaryClick(): void {
        if (this.showAlmostCompleteDialog) {
          this.showAlmostCompleteDialog = false;
          this.openPlayStore();
        }
        else if (this.showSubscriptionCodeValidModal) {
          this.showSubscriptionCodeValidModal = false;
          this.loginForSubscriptionCode();
        }
      },

      async openPlayStore(): Promise<void> {
        await this.openInNewTab(process.env.PLAY_STORE_URI);
      },

      async openAppStore(): Promise<void> {
        await this.openInNewTab(process.env.APP_STORE_URI);
      },

      async openInNewTab(uri: string): Promise<void> {
        window.open(uri, "_blank");
      },

      async registerForSubscriptionCode(): Promise<void> {
        setState("Login-Or-Register");
        await requestAuth({
          performAuthOnEmpty: true,
          registerExpected: true
        });
      },

      async loginForSubscriptionCode(): Promise<void> { // 3BB
        setState("Login-Or-Register");
        await requestAuth({
          performAuthOnEmpty: true,
        })
      },

      async supplySubscriptionCode(): Promise<void> { // 5BB
        const code = loadVar("Code");

        const user = await requestAuth({
          performAuthOnEmpty: false
        });

        if (user === null)
          throw Error("Not logged in");

        const accessToken = user.access_token;
        const supplyResponse = await api.supplyCode(code, accessToken);

        clearState();

        this.subscriptionAppTitle = supplyResponse.productTitle;
        this.showAlmostCompleteDialog = true;
      },

      async activateCodeButtonClicked(): Promise<void> {
        try {
          const code = await codeSyntaxChecker.format(this.code);
          const recaptchaCode = await recaptchaUtils.verify((this.$refs as any)["code-form"]);

          const response = await api.validateCode({
            code,
            recaptchaCode
          });

          saveVar("Code", code);

          if (response.type == "REDIRECT") {
            await this.openInNewTab(response.uri);
          } else if (response.type == "SUBSCRIPTION") {
            const user = await requestAuth({
              performAuthOnEmpty: false
            });

            if (user === null) {
              this.showSubscriptionCodeValidModal = true;
            } else {
              await this.supplySubscriptionCode();
            }
          } else {
            throw new Error("Invalid Code type");
          }
        } catch (e) {
          this.setErrorMessage(e.message);
        }
      }
    }

  });
</script>

<style lang="scss" scoped>
@import '../assets/variables';

.container {
  padding: 0 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;

  @media (min-width: $break-xl) {
    width: 75rem;
    margin: 0 auto;
  }
}

.activate-code {
  text-align: left;
}

.code {
  .code-message {
    @media (min-width: $break-sm) {
      width: 80%;
    }
    @media (min-width: $break-md) {
      width: 65%;
    }
  }

  h2 {
    font-size: 1.375rem;
    line-height: 1.5625rem;
    font-weight: 400;
    margin-bottom: 2rem;
    color: $color-accent-darkest;

    @media (min-width: $break-xs) {
      font-size: 1.75rem;
      line-height: 2.5rem;
    }
    @media (min-width: $break-sm) {
      font-size: 2rem;
      line-height: 2.5rem;
      padding-right: 6rem;
    }
  }

  .code-form {
    background: $color-primary;
    padding: 3.125rem 0 .9375rem;

    @media (min-width: $break-xs) {
      padding: 5rem 0 3.75rem;
    }

    form {
      width: 100%;
      margin-top: 1.25rem;

      @media (min-width: $break-xs) {
        display: flex;
      }
      @media (min-width: $break-sm) {
        width: 80%;;
      }
      @media (min-width: $break-md) {
        width: 65%;
      }

      input {
        padding: 0 .6875rem;
        font-size: 1.125rem;
        text-align: center;
        height: 3.75rem;
        border: 0;
        width: 100%;
        box-sizing: border-box;

        &:focus {
          outline: none;
        }

        &::placeholder {
          text-transform: none;
          color: $color-grey-light;
        }

        @media (min-width: $break-xs) {
          width: 75%;
        }
        @media (min-width: $break-xs) {
          text-align: left;
          padding: 0 0.6875rem;
          font-size: 1.375rem;
          padding-left: 2.063rem;
        }
      }
    }

    button {
      padding: .6875rem;
      font-size: 1.125rem;
      height: 3.75rem;
      width: 100%;
      color: white;
      background-color: $color-secondary;
      line-height: 1.125rem;
      border: 0;
      text-align: center;
      cursor: pointer;

      &:hover {
        box-shadow: 6px 6px 11px rgba(0, 0, 0, .2);
      }

      &:focus {
        outline: 0;
      }

      @media (min-width: $break-xs) {
        width: 25%;
      }
    }
  


    .error-message {
      color: $color-accent-darkest;
      font-size: .75rem;
      width: 100%;
      margin: .9375rem 0;
      display: flex;
      align-items: center;
      visibility: hidden;

      &.active {
        visibility: visible;
      }

      img {
        margin-right: 1rem;
      }

      @media (min-width: $break-sm) {
        width: 80%;;
      }
      @media (min-width: $break-md) {
        width: 65%;
      }
    }
    
  }
}
</style>

<style lang="scss">
  @import '../assets/variables';

  .error-message {
    a {
      color: $color-accent-darkest;
    }
  }
</style>
