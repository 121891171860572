function randomSafeString(): string {
	return "_" + Math.random().toString(36).substring(2, 15);
}

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
function createGlobalCallback(cb: (ignored: string) => void): string {
	const fn = randomSafeString();
	(window as any)[fn] = function(token: string) {
		cb(token);
	};
	return fn;
}

export type RecaptchaUtilsOptions = {
	recaptchaSiteKey: string
}

export default class RecaptchaUtils {
	private recaptchaSiteKey: string;

	constructor(opts: RecaptchaUtilsOptions) {
		this.recaptchaSiteKey = opts.recaptchaSiteKey;
	}

	createScript(url: string, isAsync: boolean): HTMLScriptElement {
		const scr = document.createElement("script");
		scr.setAttribute("src", url);
		scr.setAttribute("async", isAsync ? "async" : "");
		scr.setAttribute("defer", isAsync ? "defer" : "");
		return scr;
	}

	verify(codeForm: HTMLElement): Promise<string> {

		return new Promise((resolve) => {

			const codeCallbackName = createGlobalCallback(code => {
				delete (window as any)[codeCallbackName];
				resolve(code);
			});

			const div = document.createElement("div");

			div.classList.add("g-recaptcha");
			const id = randomSafeString();
			div.setAttribute("id", id);
			div.setAttribute("data-size", "invisible");
			codeForm.appendChild(div);

			const loadCallbackName = createGlobalCallback(() => {
				delete (window as any)[loadCallbackName];
				const widgetId = (window as any)["grecaptcha"].render(div, {
					sitekey: this.recaptchaSiteKey,
					callback: codeCallbackName
				});

				(window as any)["grecaptcha"].execute(widgetId);
			});

			const htmlScriptElement = this.createScript(`https://www.google.com/recaptcha/api.js?render=explicit&onload=${loadCallbackName}`, true);
			document.head.appendChild(htmlScriptElement);

		});
	}
}
