import CodeSyntaxChecker from "@/lib/CodeSyntaxChecker";
import RecaptchaUtils from "@/lib/RecaptchaUtils";
import Api from "@/lib/Api";

const codeSyntaxChecker = new CodeSyntaxChecker();
const recaptchaUtils = new RecaptchaUtils({
	recaptchaSiteKey: process.env.RECAPTCHA_SITE_KEY
});
const api = new Api({
	apiBase: process.env.API_BASE
});

export {
	codeSyntaxChecker,
	recaptchaUtils,
	api
};
