import Lockr from "lockr";

export type State =
    "Standby" |
    "Login-Or-Register";

export type KnownVars =
    "Code";

export function getState(): State {
    return Lockr.get("abo-foundry:state", "Standby");
}

export function setState(state: State) {
    Lockr.set("abo-foundry:state", state);
}

export function saveVar(key: KnownVars, value: any) {
    Lockr.set(`abo-foundry:value:${key}`, JSON.stringify(value));
}

export function loadVar(key: KnownVars) {
    const r: string = Lockr.get(`abo-foundry:value:${key}`);
    if (r === undefined)
        throw Error("Variable not saved");
    return JSON.parse(r);
}

export function clearState() {
    Lockr.rm("abo-foundry:state");
    Lockr.rm("abo-foundry:value:Code");
}
