import {createRouter, createWebHistory} from "vue-router";
import ActivateCode from "../components/ActivateCode.vue";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: "/abo-foundry/activate-code",
            component: ActivateCode
        }
    ]
})


export default router;
