<template>
  <div class="info">
    <div class="container">
      <div class="info-text">
        <p>
          Du möchtest dich <b>mit einem Zugangscode für unser Online-Wörterbuch Premium</b> aus einem der Langenscheidt Wörterbücher oder Sprachkurse, die mit Hinweis <b>Buch + Online</b> gekennzeichnet sind registrieren? Dann hier entlang zum <a href="https://woerterbuch.langenscheidt.com/zugangscode-nutzen/">Langenscheidt Online-Wörterbuch Premium</a>.
        </p>
      </div>
    </div>
  </div>

</template>

<script>

import {defineComponent} from "vue";

export default defineComponent({
  name: "Info",
});

</script>

<style lang="scss" scoped>
@import '../assets/variables';

.container {
  padding: 0 1.25rem;
  display: flex;
  justify-content: center;

  @media (min-width: $break-xl) {
    width: 75rem;
    margin: 0 auto;
  }
}

.info {
  margin: 3.813rem 0;

  @media (min-width: $break-sm) {
    margin: 6.5rem 0;
  }

  .info-text {
    @media (min-width: $break-sm) {
      width: 65%;
    }
    @media (min-width: $break-md) {
      width: 70%;
    }

    p {
      line-height: 1.9em;
      a {
        color: $color-accent-darkest;
      }
    }
  }

}

</style>
