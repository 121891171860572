<template>
  <div class="modal">
    <div class="dialog">
      <div class="header">
        <h1>{{ content.title }}</h1>
        <button class="button-close" @click.prevent="onCloseClick">
          <img src="../assets/close.svg"/>
        </button>
      </div>
      <p class="description">
        {{ content.description }}
      </p>

      <!--
      <p class="error-message" v-if="content.title === 'Dein Code ist gültig!'">
        <img src="../assets/attention.svg" alt="Attention symbol"/>
        Aufgrund von Wartungsarbeiten ist aktuell die Registrierung nicht möglich. Bitte versuche es später noch einmal. Dein Code ist weiterhin gültig.
      </p>
      -->

      <div class="actions">
        <button v-if="content.secondaryAction" class="button-secondary" @click.prevent="onSecondaryClick">
          {{ content.secondaryAction }}
        </button>
        <div v-else class="badge" @click.prevent="onSecondaryClick">
          <img src="../assets/Download_on_the_App_Store_Badge_DE_RGB_blk_092917.svg" alt="Download on the App Store"/>
        </div>
        <button v-if="content.primaryAction" class="button-primary" @click.prevent="onPrimaryClick">
          {{ content.primaryAction }}
        </button>
        <div v-else class="badge" @click.prevent="onPrimaryClick">
          <img src="../assets/Download_on_the_Google_Play_Store.svg" alt="Download on the Google Play Store"/>
        </div>
      </div>
      <div class="more">
        <div class="more-link" v-if="content.textLinkAction" @click.prevent="onMoreClick">{{ content.textLinkAction }}</div>
        <p v-if="content.infoText" class="info">{{ content.infoText }}</p>
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from "vue";

  export default defineComponent({
    name: "AlmostCompleteDialog",
    props: ["content"],
    methods: {
      onSecondaryClick() {
        this.$emit("handle-secondary-click");
      },

      onPrimaryClick() {
        this.$emit("handle-primary-click");
      },

      onMoreClick() {
        this.$emit("handle-more-click");
      },

      onCloseClick() {
        this.$emit("handle-close-click");
      }
    }
  });
</script>

<style lang="scss" scoped>
  @import '../assets/variables';

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;

    .dialog {
      width: 95vw;
      background-color: white;
      padding: 1.5rem;
      box-sizing: border-box;

      @media (min-width: $break-xs) {
        width: 90vw;
      }
      @media (min-width: $break-sm) {
        padding: 2.5rem;
        width: 75vw;
      }
      @media (min-width: $break-md) {
        width: 60vw;
        padding: 3.625rem;
      }
      @media (min-width: $break-lg) {
        width: 50vw;
      }
      @media (min-width: $break-xl) {
        width: 40vw;
      }

      .header {
        display: flex;
        align-items: stretch;
        flex-direction: row;

        h1 {
          font: inherit;
          flex: 1;
          margin: 0;
          font-family: "Handelson", Arial, Helvetica, sans;
          font-size: 2.375rem;
        }

        .button-close {
          position: relative;
          right: -1rem;
          top: -0.2rem;
          height: 1.5rem;
          width: 1.5rem;;
          background: none;
          border: none;
          padding: 0.75rem;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: content-box;
          outline: 0 !important;
        }
      }

      p {
        font: inherit;
        font-size: 1.063rem;
        color: $color-grey-light;
        margin: 1.5rem 0;
        line-height: 1.5rem;

        @media (min-width: $break-xs) {
          margin: 2.5rem 0;
        }
        @media (min-width: $break-sm) {
          margin: 3rem 0;
        }
      }

      .error-message {
        color: $color-accent-darkest;
        font-size: .75rem;
        width: 100%;
        margin: .9375rem 0;
        display: flex;
        align-items: center;
        line-height: 1.5;
        margin-bottom: 2rem;
        
        img {
          margin-right: 1rem;
        }
      }

      .actions {
        display: flex;
        align-items: stretch;

        button {
          cursor: pointer;
          flex: 1;
          padding: 0.875rem 0;
          border: 2px solid #0e242f;
          font-weight: 500;
          font-size: 1rem;
          outline: 0 !important;

          &:hover {
            box-shadow: 6px 6px 11px rgba(0,0,0,.2);
          }
        }

        button, .badge {
          cursor: pointer;

          img {
            transform: scale(.9);
            transform-origin: left top;
            @media (min-width: $break-xs) {
              transform: scale(1);
            }
          }
        }

        .button-secondary, .badge {
          background: none;

          @media (min-width: $break-xs) {
            margin-right: 1.25rem;
          }
        }

        .button-primary {
          color: white;
          background-color: #0e242f;
        }
      }
    }

    .more {
      .more-link {
        color: $color-accent-darkest;
        margin: 1.5rem 0;
        display: inline-block;
        text-decoration: underline;
        cursor: pointer;

        @media (min-width: $break-xs) {
          margin: 2.5rem 0;
        }
        @media (min-width: $break-sm) {
          margin: 3rem 0;
        }
      }
      .info {
        margin-top: 0;
      }
    }
  }

</style>