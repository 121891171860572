export default class CodeSyntaxChecker {
    async format(code: string): Promise<string> {
        if (code === "")
            throw new Error("Code must not be empty");

        const tempRaw = code.toUpperCase().replace(/-/g, "");
        if (code.length > 10)
            return tempRaw.substr(0, 5) + "-" + tempRaw.substr(5, 5) + "-" + tempRaw.substr(10);
        else if (code.length > 5)
            return tempRaw.substr(0, 5) + "-" + tempRaw.substr(5, 5);
        else
            return tempRaw;
    }
}