import "whatwg-fetch";

export interface ValidateCodeOpts {
    code: string;
    recaptchaCode: string
}

export interface ApiOptions {
    apiBase: string
}

export default class Api {
    private apiBase: string;

    constructor(opts: ApiOptions) {
        this.apiBase = opts.apiBase;
    }

    async fetch(relativePath: string, args: any): Promise<any> {
        return await fetch(`${this.apiBase}${relativePath}`, args);
    }

    async doJsonPostRequest(relativePath: string, body: any, headers?: Record<string, string>): Promise<any> {

        if (headers === undefined)
            headers = {};

        if (!headers["Content-Type"])
            headers["Content-Type"] = "application/json";

        return await this.fetch(relativePath, {
            method: "post",
            mode: "cors",
            cache: 'no-cache',
            redirect: "error",
            credentials: "omit",
            headers,
            body: JSON.stringify(body)
        })
    }

    async validateCode(opts: ValidateCodeOpts): Promise<any> {
        const response = await this.doJsonPostRequest("/verify-code", {
            code: opts.code,
            captcha: opts.recaptchaCode
        });

        const obj = await response.json();

        if (response.status >= 400) {

            if (obj["message"] !== undefined)
                throw Error(obj.message);
            else
                throw Error(response.statusText); // TODO: Error message
        }

        return obj;
    }

    async supplyCode(code: string, accessToken: string): Promise<any> {

        const response = await this.doJsonPostRequest("/supply-code", {
            code
        }, {
            "Authorization": `Bearer ${accessToken}`
        });

        if (response.status >= 500)
            throw Error(response.statusText);

        const json = await response.json();

        if (response.status >= 400) {
            throw Error(response.statusText); // TODO: Error message
        }

        return json;
    }
}
